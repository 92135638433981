<template>
  <b-overlay :show="loading">
    <b-container fluid class="p-0">
      <b-row>
        <b-col cols="6">
          <b-card class="card" header-class="p-6">
            <template #header>
              <div
                class="d-flex justify-content-between align-items-center"
                style="width: 100%"
              >
                <h6 class="mb-0">Xu hướng đường huyết</h6>
                <multiselect
                  class="mutiselect-rounded"
                  style="color: #333"
                  v-model="trendSelected"
                  :options="trendOptions"
                  :searchable="false"
                  :show-labels="false"
                  label="text"
                ></multiselect>
              </div>
            </template>

            <div style="height: 340px; margin: auto">
              <v-chart autoresize ref="trend" />
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="card" header-class="p-6">
            <template #header>
              <h6 class="mb-0 line-height-select">
                Tần suất phân bổ chỉ số đường huyết
              </h6>
            </template>
            <div style="height: 340px; margin: auto; overflow: hidden">
              <v-chart :option="donutOptions" autoresize ref="distribute" />
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="card" header-class="p-6">
            <template #header>
              <div
                class="d-flex w-100 justify-content-between align-items-center"
              >
                <h6 class="mb-0">So sánh chỉ số đường huyết</h6>
                <multiselect
                  class="mutiselect-rounded"
                  style="color: #333"
                  v-model="compareSelected"
                  :options="compareOptions"
                  :searchable="false"
                  :show-labels="false"
                  label="text"
                ></multiselect>
              </div>
            </template>
            <div style="height: 340px; margin: auto">
              <v-chart
                v-if="compareSelected.value == 1"
                :option="eatColumnOptions"
                autoresize
              />
              <v-chart v-else :option="workColumnOptions" autoresize />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { use } from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, BarChart, ScatterChart } from 'echarts/charts';
import VChart from 'vue-echarts';
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkAreaComponent,
  ScatterChart,
  PieChart,
  BarChart,
]);
export default {
  name: 'BloodSugarTab',
  components: { Multiselect, VChart },
  props: {
    selectDay: {
      type: Number,
      default: 1,
    },
    userId: {
      type: String,
    },
    diabeteText: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      optionTrend: {},
      compareOptions: [
        { text: 'Trước & sau tập', value: 2 },
        { text: 'Trước & sau ăn', value: 1 },
      ],
      trendLineOptions: {
        dataZoom: {
          start: 0,
          type: 'inside',
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        tooltip: {
          trigger: 'axis',
        },
        itemStyle: {
          borderType: 'dotted',
        },

        series: [
          {
            data: [],
            type: 'line',
            symbolSize: 5,
            symbol: 'circle',
            lineStyle: {
              color: '#888C9F',
              width: 1,
            },
            markArea: {
              silent: true,
              itemStyle: {
                opacity: 0.3,
                color: '#21A567',
              },
              data: [
                [
                  {
                    yAxis: 70,
                  },
                  {
                    yAxis: 180,
                  },
                ],
              ],
              label: {
                color: null,
              },
            },
          },
        ],
      },
      compareSelected: { text: 'Trước & sau ăn', value: 1 },
      eatColumnOptions: {
        dataZoom: {
          start: 0,
          type: 'inside',
        },
        barMaxWidth: 40,
        responsive: true,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: {},
        },
        color: ['#01645A', '#FDB913'],
        legend: {
          data: ['Trước ăn', 'Sau ăn'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        xAxis: {
          type: 'category',
          data: [],
          // data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)'],
        },
        series: [
          {
            name: 'Trước ăn',
            type: 'bar',
            data: [],
          },
          {
            name: 'Sau ăn',
            type: 'bar',
            data: [],
          },
        ],
      },
      workColumnOptions: {
        dataZoom: {
          start: 0,
          type: 'inside',
        },
        barMaxWidth: 40,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: {},
        },
        color: ['#01645A', '#FDB913'],
        legend: {
          data: ['Trước tập luyện', 'Sau tập luyện'],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        xAxis: {
          type: 'category',
          data: [],
        },
        series: [
          {
            name: 'Trước tập luyện',
            type: 'bar',
            data: [],
          },
          {
            name: 'Sau tập luyện',
            type: 'bar',
            data: [],
          },
        ],
      },
      // trendSelected: { text: 'Nửa đêm', value: 6 },
      trendSelected: { text: 'Tất cả', value: 1 },
      trendOptions: [
        { text: 'Tất cả', value: 1 },
        { text: 'Trước ăn', value: 2 },
        { text: 'Sau ăn', value: 3 },
        { text: 'Trước tập', value: 4 },
        { text: 'Sau tập', value: 5 },
        { text: 'Nửa đêm', value: 6 },
      ],
      lineOptions: {
        dataZoom: {
          start: 0,
          type: 'inside',
        },
        type: 'line',
        dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 1,
          curve: 'straight',
        },
        title: {
          text: 'Average High & Low Temperature',
          align: 'left',
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        tooltip: {
          intersect: false,
        },
        markers: {
          shape: 'circle',
          size: 1,
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          title: {
            text: 'Month',
          },
        },
        yaxis: {
          max: 70,
        },
      },
      trendScatterOption: {
        dataZoom: {
          start: 0,
          type: 'inside',
        },
        tooltip: {
          shared: true,
          show: true,
          // formatter: function (val) {
          //   let template = '';
          //   for (let el of val) {
          //     let [, value] = el.value;
          //     template += `${el.data.timeFrame}: <b>${value}</b> mg/DL <br/>`;
          //   }
          //   return `<span style="font-weight:600;color:#B5B5C3;">${val[0].axisValue}</span><br/> ${template}`;
          // },
        },
        xAxis: {
          type: 'category',
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            symbolSize: 20,
            data: [],
            type: 'scatter',
          },
        ],
      },
      donutOptions: {
        height: 350,

        label: {
          formatter: '{b}  {d}%  ',
        },
        labelLine: {
          smooth: 0.2,
          length: 15,
          length2: 220,
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
      scatterChartSeries: [],
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: 1,
        takeAll: true,
      },
    };
  },
  computed: {
    patientId() {
      return this.userId;
    },
  },
  watch: {
    selectDay: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal;
        this.loadData();
      },
      deep: true,
      immediate: true,
    },
    trendSelected: {
      deep: true,
      handler(newVal) {
        if (newVal.value) {
          this.getTrend();
        }
      },
    },
    compareSelected: {
      deep: true,
      handler(newVal) {
        if (newVal.value) {
          this.getCompare();
        }
      },
    },
  },
  methods: {
    async getDistribution() {
      this.loading = true;
      this.$refs.distribute?.clear();
      try {
        let { data } = await this.$api.get('Admin/Glucose/Distribution', {
          params: { ...this.searchParams, patientId: this.patientId, thresholdType: this.diabeteText === "Đái tháo đường thai kỳ" ? 1 : 0 },
        });

        let series = [
          {
            value: data.veryLowCount > 0 ? data.veryLowCount : null,
            itemStyle: { color: data.veryLowColor },
            name: 'Rất thấp',
          },
          {
            value: data.lowCount > 0 ? data.lowCount : null,
            itemStyle: { color: data.lowColor },
            name: 'Thấp',
          },
          {
            value: data.goodCount > 0 ? data.goodCount : null,
            itemStyle: { color: data.goodColor },
            name: 'Tốt',
          },
          {
            value: data.highCount > 0 ? data.highCount : null,
            itemStyle: { color: data.highColor },
            name: 'Cao',
          },
          {
            value: data.veryHighCount > 0 ? data.veryHighCount : null,
            itemStyle: { color: data.veryHighColor },
            name: 'Rất cao',
          },
        ];
        this.donutOptions.series[0].data = series;

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toastr.e('Lỗi server', 'ERROR');
      }
    },
    loadData() {
      this.getDistribution();
      this.getCompare();
      this.getTrend();
    },

    async getCompare() {
      this.resetData();
      try {
        let { data } = await this.$api.get('Admin/Glucose/Comparer', {
          params: {
            ...this.searchParams,
            patientId: this.patientId,
            comparerType: this.compareSelected.value,
          },
        });
        for (const el of data) {
          if (this.compareSelected.value == 1) {
            this.eatColumnOptions.xAxis.data.push(
              this.$moment(el.date * 1000).format('DD/MM/YYYY'),
            );
            this.eatColumnOptions.series[0].data.push({
              value: el.preGlucose,
              itemStyle: {
                color: el.preGlucoseColor,
              },
            });
            this.eatColumnOptions.series[1].data.push({
              value: el.postGlucose,
              itemStyle: {
                color: el.postGlucoseColor,
              },
            });
          } else {
            this.workColumnOptions.xAxis.data.push(
              this.$moment(el.date * 1000).format('DD/MM/YYYY'),
            );
            this.workColumnOptions.series[0].data.push({
              value: el.preGlucose,
              itemStyle: {
                color: el.preGlucoseColor,
              },
            });
            this.workColumnOptions.series[1].data.push({
              value: el.postGlucose,
              itemStyle: {
                color: el.postGlucoseColor,
              },
            });
          }
        }
      } catch (error) {
        this.$toastr.e('Lỗi server', 'ERROR');
      }
    },
    async getTrend() {
      try {
        let { data } = await this.$api.get('Admin/Glucose/Trend', {
          params: {
            ...this.searchParams,
            patientId: this.patientId,
            timeFrameType: this.trendSelected.value,
            thresholdType: this.diabeteText === "Đái tháo đường thai kỳ" ? 1 : 0 
          },
        });
        let scatterChart = data.trendItems.items
          .map((el) => {
            return el.subTrends.map((e) => ({
              name: e.type,
              value: [
                this.$moment(el.date * 1000).format('DD/MM/YYYY'),
                e.glucose,
              ],
              timeFrame: e.timeFrameName,
              itemStyle: {
                color: e.color,
              },
            }));
          })
          .reduce((a, b) => a.concat(b), []);

        // TODO: markArea good range
        this.$refs.trend?.clear();
        if (this.trendSelected.value == 1) {
          this.scatterChartSeries = scatterChart;
          this.trendScatterOption.series[0].data = scatterChart;
          this.$refs.trend?.setOption(this.trendScatterOption, true);
        } else {
          const seriesLine = [];
          const categoryLine = [];
          data.trendItems.items.forEach((e) => {
            for (const el of e.subTrends) {
              categoryLine.push(
                this.$moment(el.date * 1000).format('DD/MM/YYYY'),
              );
              seriesLine.push({
                value: el.glucose,
                name: el.type,
                itemStyle: {
                  color: el.color || '#21A567',
                },
              });
            }
          });
          this.trendLineOptions.xAxis.data = categoryLine;
          this.trendLineOptions.series[0].data = seriesLine;
          this.$refs.trend?.setOption(this.trendLineOptions, true);
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      }
    },
    resetData() {
      this.eatColumnOptions.xAxis.data = [];
      this.workColumnOptions.xAxis.data = [];
      this.eatColumnOptions.series[0].data = [];
      this.eatColumnOptions.series[1].data = [];
      this.workColumnOptions.series[0].data = [];
      this.workColumnOptions.series[1].data = [];
    },
  },

  created() {
    // this.loadData();
  },
};
</script>

<style lang="scss">
.line-height-select {
  line-height: 39px;
}
div.echarts {
  width: 100%;
  height: 100%;
}
</style>
